@use '@glise/styles/globals' as *;
.NotFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  width: 100%;
  position: relative;
  //height: 100vh;

  .NotFoundWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    z-index: 5;
    width: 100%;
    max-width: 75rem;

    @include md {
      flex-direction: column;
      align-items: center;
    }

    .NotFoundTextContainer {
      z-index: 5;
      max-width: 25rem;
      display: flex;
      gap: 1.5rem;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      @include md {
        gap: 0rem;
        margin-top: 10rem;
      }

      .BigText {
        text-align: start;
        width: 25rem;
        background: linear-gradient(170deg, #fff 19.13%, #5c44f2 125.28%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @include colorfulTextBig;

        @include md {
          padding-bottom: 1rem;
          width: 90vw;
        }
      }

      .SmallText {
        color: $darkThemeBaseWhiteDarkest;
        max-width: 17.8rem;

        @include colorfulTextSmall;

        @include md {
          padding-bottom: 1rem;
        }
      }
    }

    .ButtonsWrapper {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      white-space: nowrap;

      button {
        display: flex;
        height: 2.8rem;
        padding: 0.6875rem 1rem;
        justify-content: center;
        align-items: center;
        gap: 0.25rem;

        font-size: 1rem;
        line-height: 1.25rem;
      }

      .ButtonHome {
        border-radius: 0.375rem;
        border: 1px solid $darkThemeBaseDarkDarker;
      }
    }

    .NotFoundLogoContainer {
      z-index: 1;

      img {
        max-width: 34.483rem;
        height: 100%;
        flex-shrink: 0;

        @include md {
          max-width: 20rem;
          padding-top: 4rem;
        }
      }
    }
  }
}
